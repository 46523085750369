import {
  ActionFunctionArgs,
  MetaFunction,
  json,
  redirect,
} from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import {
  UserSession,
  redirectIfLoggedInLoader,
  setAuthOnResponse,
} from "~/auth/auth";
import { validate } from "./validate.server";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";
import { CircleHelpIcon, InfoIcon, RefreshCwIcon } from "lucide-react";
import { signIn } from "@repo/models/lib/auth.queries";
import { useEffect, useState } from "react";
import posthogJS from "posthog-js";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";

export const meta: MetaFunction = () => {
  return [
    { title: "Sign in - TrueCold" },
    { name: "description", content: "Welcome to TrueCold!" },
  ];
};

export const loader = redirectIfLoggedInLoader;

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const connect = url.searchParams.get("connect");

  const formData = await request.formData();
  const email = String(formData.get("email") || "");
  const password = String(formData.get("password") || "");

  console.log("Email:", email);
  console.log("Password:", password);

  const errors = validate(email, password);
  if (errors) {
    console.log("Validation errors:", errors);
    return json({ ok: false, errors }, 400);
  }

  const user = await signIn(email, password);
  console.log("User sign-in result", user);
  
  if (user === false) {
    return json(
      { ok: false, errors: { password: "Invalid credentials" } },
      400,
    );
  }

  let redirectTo = "/";
  if (connect) {
    redirectTo = `/connect/${connect}`;
  }

  return setAuthOnResponse(redirect(redirectTo), user as UserSession);
}

export default function SignIn() {
  const navigation = useNavigation();
  const isSubmitting = navigation.formAction === "/sign-in";
  const actionData = useActionData<typeof action>();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const connect = searchParams.get("connect");

  useEffect(() => {
    posthogJS.reset();
  }, []);

  return (
    <div className="container relative grid h-screen flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-6 w-6"
          >
            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
          </svg>
          TrueCold
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-1">
            <p className="text-lg">Never lose a package.</p>
            <p className="text-lg">We help to track your cold shipments.</p>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-96 flex-col justify-center space-y-6">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Sign in your account
            </h1>
          </div>
          {connect && (
            <Alert variant="info">
              <CircleHelpIcon className="h-4 w-4" />
              <AlertTitle>Accounts connection</AlertTitle>
              <AlertDescription>
                You have been redirected here after trying to connect with
                another account, after the sign-in we'll connect both accounts.
              </AlertDescription>
            </Alert>
          )}
          <Form method="post" className="grid gap-4">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="email">Email</Label>
              <Input type="email" id="email" name="email" placeholder="Email" />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="email">Password</Label>
              <Input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="*********"
              />
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  className="my-0 h-6 p-0 font-normal text-muted-foreground"
                  variant={"link"}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
                <Button
                  className="my-0 h-6 p-0 text-muted-foreground"
                  variant={"link"}
                  asChild
                >
                  <Link to="/reset-password">Forgot password?</Link>
                </Button>
              </div>
              {actionData?.errors?.password && (
                <div className="ml-3 text-xs tracking-tight text-red-600">
                  {actionData.errors.password}
                </div>
              )}
            </div>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="default"
              className="w-full"
            >
              {isSubmitting && (
                <RefreshCwIcon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Sign in
            </Button>
          </Form>
          <p className="px-8 text-center text-sm text-muted-foreground">
            By clicking continue, you agree to our{" "}
            <Link
              to="/terms"
              className="underline underline-offset-4 hover:text-primary"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
